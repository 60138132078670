var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/wrapDispose.ts
Symbol.dispose ??= Symbol("Symbol.dispose");
Symbol.asyncDispose ??= Symbol("Symbol.asyncDispose");
var wrapDispose = /* @__PURE__ */ __name((fn) => {
  const wrapped = /* @__PURE__ */ __name(() => fn(), "wrapped");
  wrapped[Symbol.dispose] = () => wrapped();
  return wrapped;
}, "wrapDispose");

// src/Registrator.ts
var OmnibusRegistrator = class {
  static {
    __name(this, "OmnibusRegistrator");
  }
  #bus;
  #registered;
  constructor(bus) {
    this.#bus = bus;
    this.#registered = [];
  }
  on(event, fn) {
    const wrappedFn = /* @__PURE__ */ __name((...args2) => fn(...args2), "wrappedFn");
    this.#bus.on(event, wrappedFn);
    this.#registered.push({
      name: event,
      wrappedFn,
      fn
    });
    return () => {
      this.off(event, wrappedFn);
    };
  }
  off(name, fn) {
    const properCall = this.#registered.find((entry) => entry.name === name && entry.fn === fn);
    if (!properCall) {
      throw new Error("Event you are trying to unregister was not registered via this Registrator");
    }
    this.#bus.off(name, properCall.wrappedFn);
    this.#registered = this.#registered.filter((entry) => !(entry.name === name && entry.fn === fn));
  }
  offAll() {
    this.#registered.forEach(({ name, wrappedFn }) => {
      this.#bus.off(name, wrappedFn);
    });
    this.#registered = [];
  }
  [Symbol.dispose]() {
    this.offAll();
  }
};

// src/Builder.ts
var Builder = class _Builder {
  static {
    __name(this, "Builder");
  }
  call = async (t, ...p) => t(...p);
  map(fn) {
    const prev = this.call;
    this.call = async (t, ...p) => {
      const trigger = /* @__PURE__ */ __name(async (...p2) => {
        const data = await fn(...p2);
        const arrayData = Array.isArray(data) ? data : [
          data
        ];
        await t(...arrayData);
      }, "trigger");
      await prev(trigger, ...p);
    };
    return this;
  }
  filter(filt) {
    const prev = this.call;
    this.call = async (t, ...p) => {
      if (await filt(...p)) {
        await prev(t, ...p);
      }
    };
    return this;
  }
  reduce(fn, init) {
    const prev = this.call;
    let acc = Array.isArray(init) ? init : [
      init
    ];
    this.call = async (t, ...p) => {
      const trigger = /* @__PURE__ */ __name(async (...p2) => {
        const newVal = await fn(...[
          ...p2,
          ...acc
        ]);
        acc = Array.isArray(newVal) ? newVal : [
          newVal
        ];
        await t(newVal);
      }, "trigger");
      await prev(trigger, ...p);
    };
    return this;
  }
  shouldMemoize = false;
  memoize() {
    this.shouldMemoize = true;
    return this;
  }
  static init() {
    return new _Builder();
  }
};
var BuildableBuilder = class _BuildableBuilder extends Builder {
  static {
    __name(this, "BuildableBuilder");
  }
  build() {
    if (!this.shouldMemoize) {
      return this.call;
    }
    const mem = /* @__PURE__ */ new Map();
    return async (t, ...p) => {
      const hash = p.map((el) => el.toString()).join("::::");
      if (mem.has(hash)) {
        return mem.get(hash);
      }
      const newT = /* @__PURE__ */ __name((...res) => {
        mem.set(hash, res);
        return t(...res);
      }, "newT");
      this.call(newT, ...p);
    };
  }
  static init() {
    return new _BuildableBuilder();
  }
  // We need to cast map, this looks nasty, not sure if there's better way of doing it in TS
  map(fn) {
    return super.map(fn);
  }
  reduce(fn, init) {
    return super.reduce(fn, init);
  }
};
var args = /* @__PURE__ */ __name(() => new Builder(), "args");

// src/BusBuilder.ts
var isOldschoolOnRegistrator = /* @__PURE__ */ __name((b, e) => {
  return !!b[`on${e}`] && typeof b[`on${e}`] === "function";
}, "isOldschoolOnRegistrator");
var isEventListenerRegistrator = /* @__PURE__ */ __name((b) => {
  return !!b["addEventListener"] && typeof b["addEventListener"] === "function";
}, "isEventListenerRegistrator");
var isCallbackRegistrator = /* @__PURE__ */ __name((b) => {
  return typeof b === "function";
}, "isCallbackRegistrator");
var isOmnibusRegistrator = /* @__PURE__ */ __name((b) => {
  return !!b["on"] && typeof b["on"] === "function";
}, "isOmnibusRegistrator");
var BusBuilder = class _BusBuilder {
  static {
    __name(this, "BusBuilder");
  }
  #transformers = /* @__PURE__ */ new Map();
  register(name, _) {
    return this;
  }
  derive(targetKey, sourceKey, fn) {
    if (!this.#transformers.has(sourceKey)) {
      this.#transformers.set(sourceKey, []);
    }
    const transformer = fn(new BuildableBuilder()).build();
    this.#transformers.set(sourceKey, [
      ...this.#transformers.get(sourceKey),
      {
        targetKey,
        transformer
      }
    ]);
    return this;
  }
  #registers = [];
  from(bus, event, newEvent) {
    const register = /* @__PURE__ */ __name((omnibus) => {
      if (isEventListenerRegistrator(bus)) {
        const cb = /* @__PURE__ */ __name((...v) => {
          omnibus.trigger(newEvent || event, ...v);
        }, "cb");
        bus.addEventListener(event, cb);
      }
      if (isOmnibusRegistrator(bus)) {
        bus.on(event, (...v) => omnibus.trigger(newEvent || event, ...v));
      }
      if (isOldschoolOnRegistrator(bus, event)) {
        const prevCall = bus[`on${event}`](bus)[`on${event}`] = (...v) => {
          omnibus.trigger(newEvent || event, ...v);
          return prevCall(...v);
        };
        return;
      }
      if (isCallbackRegistrator(bus)) {
        bus(event, (...v) => omnibus.trigger(newEvent || event, ...v));
        return;
      }
    }, "register");
    this.#registers.push(register);
    return this;
  }
  build() {
    const bus = new Omnibus(this.#transformers);
    this.#registers.forEach((r) => r(bus));
    return bus;
  }
  static init() {
    return new _BusBuilder();
  }
};

// src/Omnibus.ts
var Omnibus = class {
  static {
    __name(this, "Omnibus");
  }
  #callbacks;
  #transformers;
  constructor(transformers) {
    this.#callbacks = /* @__PURE__ */ new Map();
    this.#transformers = transformers || /* @__PURE__ */ new Map();
    this.on = this.on.bind(this);
  }
  static builder() {
    return new BusBuilder();
  }
  on(event, fn) {
    if (!this.#callbacks.has(event)) {
      this.#callbacks.set(event, []);
    }
    const arr = this.#callbacks.get(event);
    arr.push(fn);
    return wrapDispose(() => {
      this.off(event, fn);
    });
  }
  once(event, cb) {
    if (cb) {
      const callback = /* @__PURE__ */ __name((...d) => {
        this.off(event, callback);
        cb(...d);
      }, "callback");
      return this.on(event, callback);
    }
    return new Promise((resolve) => {
      const unregister = this.on(event, (...data) => {
        unregister();
        if (data.length === 1) {
          resolve(data[0]);
        } else {
          resolve(data);
        }
      });
    });
  }
  off(event, fn) {
    const callbacks = this.#callbacks.get(event) || [];
    this.#callbacks.set(event, callbacks.filter((f) => f !== fn));
  }
  offAll() {
    this.#callbacks = /* @__PURE__ */ new Map();
  }
  async trigger(event, ...args2) {
    const calls = this.#callbacks.get(event) || [];
    await Promise.all(calls.map((c) => c(...args2))).then(() => {
      return;
    });
    if (this.#transformers.has(event)) {
      await Promise.all(this.#transformers.get(event).map(({ targetKey, transformer }) => {
        return transformer((...d) => this.trigger(targetKey, ...d), ...args2);
      }));
    }
  }
  getRegistrator() {
    return new OmnibusRegistrator(this);
  }
};
export {
  BusBuilder,
  Omnibus,
  OmnibusRegistrator,
  args
};
